@import url("https://fonts.googleapis.com/css2?family=Nunito&family=Roboto:wght@100;300;400&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Nunito",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.leftlivejobcardscroll::-webkit-scrollbar {
  display: block;
  width: 10px;
  /* border: 5px solid white; */
}

.leftlivejobcardscroll::-webkit-scrollbar-thumb {
  background-color: #c0c2c3;
  /* background-clip: padding-box;
  border: 0.05em solid #eeeeee; */
}

.leftlivejobcardscroll::-webkit-scrollbar-track {
  background-color: #e0e2e3;
  border-radius: 2px;
}

.leftlivejobcardscroll::-webkit-scrollbar-button:single-button {
  background-color: #e0e2e3;
  display: block;
  border-style: solid;
  height: 10px;
  width: 10px;
  border-radius: 2px;
}

.leftlivejobcardscroll::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #555555 transparent;
}

.leftlivejobcardscroll::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #777777 transparent;
}

.leftlivejobcardscroll::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 5px 5px 0 5px;
  border-color: #555555 transparent transparent transparent;
}

.leftlivejobcardscroll::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
}

.livejobcardscroll::-webkit-scrollbar {
  display: block;
  width: 6px;
  height: 10px;
}

.livejobcardscroll::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 50px;
}
.livejobcardscroll::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 50px;
}

/* ::-webkit-scrollbar {
  display: none;
} */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/*========== NAV ==========*/
.nav {
  top: 0;
  left: -100%;
  height: 100vh;
  /* padding: 1rem 1rem 0; */
  background-color: #fff;
  box-shadow: 1px 0 0 rgba(22, 8, 43, 0.1);
  z-index: var(--z-fixed);
  /* transition: 0.9s; */
  /* transition: all 0.2s ease-in-out; */
  /* transition: transform 250ms; */
  /* transition: margin-left 0.5s; */

  transition: 0.3s;
  padding: 6px;
}

.nav__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 3rem;
  overflow: auto;
  scrollbar-width: none; /* For mozilla */
}

/* For Google Chrome and others */
.nav__container::-webkit-scrollbar {
  display: none;
}
/* ========== MEDIA QUERIES ==========*/
/* For small devices reduce search*/

@media screen and (min-width: 768px) {
  .nav {
    left: 0;

    /* padding: 1.2rem 1.5rem 0; */
    width: 67px; /* Reduced navbar */
  }

  /* Navbar expanded */
  .nav:hover {
    width: var(--nav-width);
    /* transform: translateX(5px); */
  }
}

:root {
  --nav-width: 220px;

  --container-color: #ffffff;

  /*========== z index ==========*/
  --z-fixed: 100;
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item img {
  pointer-events: none;
  user-select: none;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  background: rgb(132, 124, 196);
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.react-resizable-hide > .react-resizable-handle {
  display: none;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-w,
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n,
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+");
  background-position: bottom right;
  padding: 0 3px 3px 0;
}
.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

.apexcharts-yaxis-title {
  margin-bottom: 30px;
}

.search-box {
  width: fit-content;
  height: fit-content;
  position: relative;
}
.input-search {
  height: 50px;
  width: 50px;
  border-style: none;
  padding: 10px;
  font-size: 14px;
  letter-spacing: 2px;
  outline: none;
  border-radius: 25px;
  transition: all 0.5s ease-in-out;
  border-color: #b5babe;
  border: 1px solid;
  /* background-color: #22a6b3; */
  padding-right: 40px;
  color: #b5babe;
}
.input-search::placeholder {
  color: rgba(66, 66, 66, 0.5);
  font-size: 14px;
  letter-spacing: 2px;
  font-weight: 100;
}
.btn-search {
  width: 50px;
  height: 50px;
  border-style: none;
  font-size: 20px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  color: #b5babe;
  background-color: transparent;
  pointer-events: painted;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-search:focus ~ .input-search {
  width: 200px;
  border-radius: 30px;
  /* background-color: transparent; */
  border-bottom: 1px solid rgba(66, 66, 66, 0.5);
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}
.input-search:focus {
  width: 200px;
  border-radius: 30px;
  /* background-color: transparent; */
  border-bottom: 1px solid rgba(66, 66, 66, 0.5);
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}
input:focus-visible {
  outline: unset !important;
}
.Mui-selected {
  background-color: unset !important;
}

::-webkit-scrollbar-thumb {
  background-color: #c0c2c3;
  /* background-clip: padding-box;
  border: 0.05em solid #eeeeee; */
}

::-webkit-scrollbar-track {
  background-color: #e0e2e3;
  border-radius: 2px;
}

::-webkit-scrollbar-button:single-button {
  background-color: #e0e2e3;
  display: block;
  border-style: solid;
  height: 10px;
  width: 10px;
  border-radius: 2px;
}

::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #555555 transparent;
}

::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #777777 transparent;
}

::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 5px 5px 0 5px;
  border-color: #555555 transparent transparent transparent;
}

::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
}

::-webkit-scrollbar-button:single-button:horizontal:decrement {
  border-width: 5px 5px 5px 0;
  border-color: transparent #555555 transparent transparent;
}

::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
  border-color: transparent #777777 transparent transparent;
}

::-webkit-scrollbar-button:single-button:horizontal:increment {
  border-width: 5px 5px 5px 5px;
  border-color: transparent transparent transparent #555555;
}

::-webkit-scrollbar-button:horizontal:single-button:increment:hover {
  border-color: transparent transparent transparent #777777;
}

::-webkit-scrollbar {
  display: block;
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 50px;
}
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 50px;
}
