.App {
  font-family: sans-serif;
  text-align: center;
}

.arrow-up {
  width: 270px;
  height: 100px;
  background: #fff;
  position: relative;
  border: 1px solid #88b7d5;
  top: 0px;
}

.arrow-up::before {
  content: " ";
  position: absolute;
  right: -15px;
  top: 10px;
  border-top: 15px solid transparent;
  border-right: none;
  border-left: 15px solid rgb(223, 143, 143);
  border-bottom: 15px solid transparent;
}

.arrow-up::after {
  content: " ";
  position: absolute;
  right: -15px;
  top: 10px;
  border-top: 15px solid transparent;
  border-right: none;
  border-left: 15px solid rgb(161, 0, 0);
  border-bottom: 15px solid transparent;
}
