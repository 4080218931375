.slick-slide > div {
  margin: 0 0px;
  //height: 180px !important;
}

.slick-slide > div:nth-child(1) {
  margin-left: 0;
}

.slick-list {
  margin-right: 0px;
}
.slick-slider {
  height: 100% !important;
}
.slick-dots li.slick-active button:before {
  color: #000;
}

.gridContainer {
  display: grid;
  grid-template-columns: auto auto;

  padding: 10px;
}
.gridItem {
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 20px;

  text-align: center;
}
