ul {
  margin: 0;
}

.dropzone {
  text-align: center;
  padding: 50px;
  border: 3px dashed #b0b7c3;
  background-color: #fafafa;
  color: #bdbdbd;
  border-radius: 10px;

  margin-bottom: 20px;
}
