.nav-text {
  display: flex;
  justify-content: start;
  /* color: #060b26; */
  align-items: center;
  /* padding: 8px 0px 8px 16px; */
  list-style: none;
  height: 50px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #1a83ff;
  text-decoration: none;
  color: #fff;
}

.nav-menu-items {
  /* width: 100%; */
  display: contents;
}

.navbar-toggle {
  background-color: #6c757d;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

span {
  /* margin-left: 16px; */
}
