@import url(https://fonts.googleapis.com/css2?family=Nunito&family=Roboto:wght@100;300;400&display=swap);
@import url(https://cdn.syncfusion.com/ej2/material.css);
:root {
  --primary: #007bff;
  --warning: #ffc107;
  --danger: #dc3545;
  --success: #28a745;
  --white: #ffffff;
  --dark: #1d1d1d;
}

.btn {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  border-radius: 30px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.btn:hover {
  transform: translateY(-3px);
}

/* Button Colors and Styles */

.btn--primary--solid {
  box-shadow: 0 2px 4px 0 rgba(44, 39, 56, 0.08);
  background-color: #007bff;

  color: var(--white);
  border: none;
}

.btn--disabled--solid {
  box-shadow: 0 2px 4px 0 rgba(44, 39, 56, 0.08);
  background-color: #58aaff;

  color: var(--white);
  border: none;
}

.btn--warning--solid {
  background-color: var(--warning);
  color: var(--dark);
  border: none;
}

.btn--danger--solid {
  background-color: var(--danger);
  color: var(--white);
  border: none;
}

.btn--success--solid {
  background-color: var(--success);
  color: var(--white);
  border: none;
}

.btn--white--solid {
  background-color: var(--white);
  color: var(--primary);
  border: none;
}

.btn--white--new {
  background-color: #fff;
  color: #007bff;
  border: none;
}

.btn--primary--outline {
  background-color: transparent;
  border: 2px solid var(--primary);
  color: var(--primary);
}

.btn--warning--outline {
  background-color: transparent;
  border: 2px solid var(--warning);
  color: var(--warning);
}

.btn--danger--outline {
  background-color: transparent;
  border: 2px solid var(--danger);
  color: var(--danger);
}

.btn--success--outline {
  background-color: transparent;
  border: 2px solid var(--success);
  color: var(--success);
}
.btn--padding--small {
  padding-left: 55px;
  padding-right: 55px;
  /* padding: 6px 16px; */
  font-size: 12px;
}
/* Button Size */
.btn--small {
  padding: 6px 16px;
  font-size: 12px;
}

.btn--medium {
  padding: 10px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 15px 30px;
  font-size: 20px;
}
/* .seperator {
    width: 100%;
  }
  
  .controls-wrapper {
    width: 100%;
  }
  
  .carousel-wrapper {
    width: 100%;
  }
  
  .rec-carousel-item:focus {
    outline: none;
    box-shadow: inset 0 0 1px 0px violet;
  }
   */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Nunito",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.leftlivejobcardscroll::-webkit-scrollbar {
  display: block;
  width: 10px;
  /* border: 5px solid white; */
}

.leftlivejobcardscroll::-webkit-scrollbar-thumb {
  background-color: #c0c2c3;
  /* background-clip: padding-box;
  border: 0.05em solid #eeeeee; */
}

.leftlivejobcardscroll::-webkit-scrollbar-track {
  background-color: #e0e2e3;
  border-radius: 2px;
}

.leftlivejobcardscroll::-webkit-scrollbar-button:single-button {
  background-color: #e0e2e3;
  display: block;
  border-style: solid;
  height: 10px;
  width: 10px;
  border-radius: 2px;
}

.leftlivejobcardscroll::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #555555 transparent;
}

.leftlivejobcardscroll::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #777777 transparent;
}

.leftlivejobcardscroll::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 5px 5px 0 5px;
  border-color: #555555 transparent transparent transparent;
}

.leftlivejobcardscroll::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
}

.livejobcardscroll::-webkit-scrollbar {
  display: block;
  width: 6px;
  height: 10px;
}

.livejobcardscroll::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 50px;
}
.livejobcardscroll::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 50px;
}

/* ::-webkit-scrollbar {
  display: none;
} */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/*========== NAV ==========*/
.nav {
  top: 0;
  left: -100%;
  height: 100vh;
  /* padding: 1rem 1rem 0; */
  background-color: #fff;
  box-shadow: 1px 0 0 rgba(22, 8, 43, 0.1);
  z-index: var(--z-fixed);
  /* transition: 0.9s; */
  /* transition: all 0.2s ease-in-out; */
  /* transition: transform 250ms; */
  /* transition: margin-left 0.5s; */

  transition: 0.3s;
  padding: 6px;
}

.nav__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 3rem;
  overflow: auto;
  scrollbar-width: none; /* For mozilla */
}

/* For Google Chrome and others */
.nav__container::-webkit-scrollbar {
  display: none;
}
/* ========== MEDIA QUERIES ==========*/
/* For small devices reduce search*/

@media screen and (min-width: 768px) {
  .nav {
    left: 0;

    /* padding: 1.2rem 1.5rem 0; */
    width: 67px; /* Reduced navbar */
  }

  /* Navbar expanded */
  .nav:hover {
    width: var(--nav-width);
    /* transform: translateX(5px); */
  }
}

:root {
  --nav-width: 220px;

  --container-color: #ffffff;

  /*========== z index ==========*/
  --z-fixed: 100;
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item img {
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  background: rgb(132, 124, 196);
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.react-resizable-hide > .react-resizable-handle {
  display: none;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-w,
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n,
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+");
  background-position: bottom right;
  padding: 0 3px 3px 0;
}
.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

.apexcharts-yaxis-title {
  margin-bottom: 30px;
}

.search-box {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
}
.input-search {
  height: 50px;
  width: 50px;
  border-style: none;
  padding: 10px;
  font-size: 14px;
  letter-spacing: 2px;
  outline: none;
  border-radius: 25px;
  transition: all 0.5s ease-in-out;
  border-color: #b5babe;
  border: 1px solid;
  /* background-color: #22a6b3; */
  padding-right: 40px;
  color: #b5babe;
}
.input-search::placeholder {
  color: rgba(66, 66, 66, 0.5);
  font-size: 14px;
  letter-spacing: 2px;
  font-weight: 100;
}
.btn-search {
  width: 50px;
  height: 50px;
  border-style: none;
  font-size: 20px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  color: #b5babe;
  background-color: transparent;
  pointer-events: painted;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-search:focus ~ .input-search {
  width: 200px;
  border-radius: 30px;
  /* background-color: transparent; */
  border-bottom: 1px solid rgba(66, 66, 66, 0.5);
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}
.input-search:focus {
  width: 200px;
  border-radius: 30px;
  /* background-color: transparent; */
  border-bottom: 1px solid rgba(66, 66, 66, 0.5);
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}
input:focus-visible {
  outline: unset !important;
}
.Mui-selected {
  background-color: unset !important;
}

::-webkit-scrollbar-thumb {
  background-color: #c0c2c3;
  /* background-clip: padding-box;
  border: 0.05em solid #eeeeee; */
}

::-webkit-scrollbar-track {
  background-color: #e0e2e3;
  border-radius: 2px;
}

::-webkit-scrollbar-button:single-button {
  background-color: #e0e2e3;
  display: block;
  border-style: solid;
  height: 10px;
  width: 10px;
  border-radius: 2px;
}

::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #555555 transparent;
}

::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #777777 transparent;
}

::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 5px 5px 0 5px;
  border-color: #555555 transparent transparent transparent;
}

::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
}

::-webkit-scrollbar-button:single-button:horizontal:decrement {
  border-width: 5px 5px 5px 0;
  border-color: transparent #555555 transparent transparent;
}

::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
  border-color: transparent #777777 transparent transparent;
}

::-webkit-scrollbar-button:single-button:horizontal:increment {
  border-width: 5px 5px 5px 5px;
  border-color: transparent transparent transparent #555555;
}

::-webkit-scrollbar-button:horizontal:single-button:increment:hover {
  border-color: transparent transparent transparent #777777;
}

::-webkit-scrollbar {
  display: block;
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 50px;
}
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 50px;
}

@keyframes modal-video {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px);
  }
  to {
    transform: translate(0, 0);
  }
}
.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  transition: opacity 0.3s ease-out;
}

.modal-video-effect-exit {
  opacity: 0;
}
.modal-video-effect-exit .modal-video-movie-wrap {
  transform: translate(0, 100px);
}

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
@media (orientation: landscape) {
  .modal-video-inner {
    padding: 10px 60px;
    box-sizing: border-box;
  }
}

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  transform: translate(0, 0);
  transition: transform 0.3s ease-out;
}
.modal-video-movie-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent;
}
@media (orientation: landscape) {
  .modal-video-close-btn {
    top: 0;
    right: -45px;
  }
}
.modal-video-close-btn:before {
  transform: rotate(45deg);
}
.modal-video-close-btn:after {
  transform: rotate(-45deg);
}
.modal-video-close-btn:before,
.modal-video-close-btn:after {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #fff;
  border-radius: 5px;
  margin-top: -6px;
}

.nav-text {
  display: flex;
  justify-content: start;
  /* color: #060b26; */
  align-items: center;
  /* padding: 8px 0px 8px 16px; */
  list-style: none;
  height: 50px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #1a83ff;
  text-decoration: none;
  color: #fff;
}

.nav-menu-items {
  /* width: 100%; */
  display: contents;
}

.navbar-toggle {
  background-color: #6c757d;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

span {
  /* margin-left: 16px; */
}

.ql-toolbar.ql-snow {
  font-family: "Nunito";
  padding: 10px;
  text-align: left;
}

.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  padding: 16px;
  border-radius: 8px 8px 0px 0px;
  background-color: #ffffff;
}
ul {
  margin: 0;
}

.dropzone {
  text-align: center;
  padding: 100px;
  border: 3px dashed #b0b7c3;
  background-color: #fafafa;
  color: #bdbdbd;
  border-radius: 10px;

  margin-bottom: 20px;
}

ul {
  margin: 0;
}

.dropzone {
  text-align: center;
  padding: 50px;
  border: 3px dashed #b0b7c3;
  background-color: #fafafa;
  color: #bdbdbd;
  border-radius: 10px;

  margin-bottom: 20px;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.arrow-up {
  width: 270px;
  height: 100px;
  background: #fff;
  position: relative;
  border: 1px solid #88b7d5;
  top: 0px;
}

.arrow-up::before {
  content: " ";
  position: absolute;
  right: -15px;
  top: 10px;
  border-top: 15px solid transparent;
  border-right: none;
  border-left: 15px solid rgb(223, 143, 143);
  border-bottom: 15px solid transparent;
}

.arrow-up::after {
  content: " ";
  position: absolute;
  right: -15px;
  top: 10px;
  border-top: 15px solid transparent;
  border-right: none;
  border-left: 15px solid rgb(161, 0, 0);
  border-bottom: 15px solid transparent;
}

.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-family: "Nunito", serif;
  font-size: 14px;
  padding: 15px;
  border-radius: 20px;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
  color: #b0b7c3;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: "Hoefler Text", "Nunito", serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: "Inconsolata", "Menlo", "Consolas", monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: "Nunito", sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  -webkit-user-select: none;
          user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

ul {
    margin: 0;
}

.dropzone {
    text-align: center;
    padding: 100px;
    border: 3px dashed #b0b7c3;
    background-color: #fafafa;
    color: #bdbdbd;
    border-radius: 10px;
    margin-bottom: 20px;
}


.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  height: auto;
}

.slick-slide > div {
  margin: 0 0px;
  //height: 180px !important;
}

.slick-slide > div:nth-child(1) {
  margin-left: 0;
}

.slick-list {
  margin-right: 0px;
}
.slick-slider {
  height: 100% !important;
}
.slick-dots li.slick-active button:before {
  color: #000;
}

.slick-slide > div {
  margin: 0 10px;
}

.slick-slide > div:nth-child(1) {
  margin-left: 0;
}

.slick-list {
  margin-right: -30px;
  height: 100% !important;
}
.slick-slider {
  height: 100% !important;
}

.slick-dots li button:before {
  font-size: 9px !important;
}

.slick-slide > div {
  margin: 0 0px;
  //height: 180px !important;
}

.slick-slide > div:nth-child(1) {
  margin-left: 0;
}

.slick-list {
  margin-right: 0px;
}
.slick-slider {
  height: 100% !important;
}
.slick-dots li.slick-active button:before {
  color: #000;
}

.gridContainer {
  display: grid;
  grid-template-columns: auto auto;

  padding: 10px;
}
.gridItem {
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 20px;

  text-align: center;
}

