.slick-slide > div {
  margin: 0 10px;
}

.slick-slide > div:nth-child(1) {
  margin-left: 0;
}

.slick-list {
  margin-right: -30px;
  height: 100% !important;
}
.slick-slider {
  height: 100% !important;
}

.slick-dots li button:before {
  font-size: 9px !important;
}
